import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login', name: 'login', meta: { title: 'Hos-Booking | Login', auth: false }, component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/auth/admin', name: 'adminAuth', meta: { title: 'Hos-Booking | Authenticating..', auth: false }, component: () => import(/* webpackChunkName: "AdminAuth" */ '../views/auth/admin.vue')
  },
  {
    path: '/chamberMaid', name: 'chamberMaid', meta: { auth: true }, component: () => import(/* webpackChunkName: "ChamberMaid" */ '../views/WebApp/ChamberMaid')
  },
  {
    path: '/houseTechnician', name: 'houseTechnician', meta: { auth: true }, component: () => import(/* webpackChunkName: "houseTechnician" */ '../views/WebApp/HouseTechnician')
  },
  {
    path: '/guestEntry', name: 'guestEntry', meta: { auth: true }, component: () => import(/* webpackChunkName: "GuestEntry" */ '../views/WebApp/GuestEntry')
  },
  {
    path: '/error', name: 'errors', meta: { auth: false }, component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/WebApp/ErrorPage')
  },
  {
    path: '/onBoard', name: 'onBoard', meta: { auth: true }, component: () => import(/* webpackChunkName: "onBoard" */ '../views/onBoard/onBoard')
  },
  {
    path: '/onBoard/reservation', name: 'onBoardReservation', meta: { auth: true }, component: () => import(/* webpackChunkName: "onBoardReservation" */ '../views/onBoard/reservation')
  },
  {
    path: '/onBoard/logo', name: 'onBoardLogo', meta: { auth: true }, component: () => import(/* webpackChunkName: "onBoardLogo" */ '../views/onBoard/logo')
  },
  {
    path: '/onBoard/mailServer', name: 'onBoardMailServer', meta: { auth: true }, component: () => import(/* webpackChunkName: "onBoardMailServer" */ '../views/onBoard/mailServer')
  },
  {
    path: '/onBoard/review', name: 'onBoardReview', meta: { auth: true }, component: () => import(/* webpackChunkName: "onBoardReview" */ '../views/onBoard/review')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "AppHome" */ '../views/Home'),
    children: [
      { path: '/myInvoices', name: 'myInvoices', component: () => import(/* webpackChunkName: "myInvoices" */ '../views/Profile/MyInvoices.vue'), meta: { title: 'My Invoices' } },
      { path: '/myInvoices/processPayment', name: 'myInvoicesProcessPayment', component: () => import(/* webpackChunkName: "myInvoicesProcessPayment" */ '../views/Profile/ProcessPayment.vue'), meta: { title: 'ProcessPayment', hideSideBar: true } },
      { path: '/myInvoices/paymentFailed', name: 'myInvoicesPaymentFailed', component: () => import(/* webpackChunkName: "myInvoicesPaymentFailed" */ '../views/Profile/PaymentFailed.vue'), meta: { title: 'Payment Failed', hideSideBar: true } },
      { path: '/myInvoices/paymentSuccess', name: 'myInvoicesPaymentSuccess', component: () => import(/* webpackChunkName: "myInvoicesPaymentSuccess" */ '../views/Profile/PaymentSuccess.vue'), meta: { title: 'Payment Success', hideSideBar: true } },
      { path: '/safetyPrecautions', name: 'safetyPrecautions', component: () => import(/* webpackChunkName: "Home" */ '../views/Extra/safetyPrecautions.vue'), meta: { title: 'Safety Precautions' } },
      { path: '/', name: 'dashBoard', component: () => import(/* webpackChunkName: "Home" */ '../views/DashBoard.vue'), meta: { title: 'Home' } },
      { path: '/rates', name: 'rates', component: () => import(/* webpackChunkName: "RatesAndCalendar" */ '../views/Rates.vue'), meta: { title: 'Rates & Calendar', hideSideBar: true } },
      { path: '/roomPlan', name: 'roomPlan', component: () => import(/* webpackChunkName: "RoomPlan" */ '../views/RoomPlan.vue'), meta: { title: 'Room Plan', hideSideBar: true } },
      { path: '/channels', name: 'channels', component: () => import(/* webpackChunkName: "Channels" */ '../views/Channel/ChannelMapping'), meta: { title: 'Channel', menuId: 'channel' } },
      { path: '/channels/ratesAvailability', name: 'channelRatesAvailability', component: () => import(/* webpackChunkName: "channelRatesAndAvailability" */ '../views/Channel/ChannelRatesAndAvailability'), meta: { title: 'Rates & Availability', menuId: 'channel', hideSideBar: true } },
      { path: '/channels/syncError', name: 'syncError', component: () => import(/* webpackChunkName: "syncError" */ '../views/Channel/syncError.vue'), meta: { title: 'Sync-Error', menuId: 'channel' } },

      // {
      //   path: '/channels',
      //   component: () => import(/* webpackChunkName: "Channels" */ '../views/Channel/Channels.vue'),
      //   meta: { title: 'Channels', menuId: 'channel' },
      //   children: [
      //     { path: '/', name: 'channel', component: () => import(/* webpackChunkName: "ChannelMapping" */ '../views/Channel/ChannelMapping'), meta: { title: 'Property Mapping', menuId: 'channel' } },
      //     { path: 'propertyMapping', name: 'channelMapping', component: () => import(/* webpackChunkName: "ChannelMapping" */ '../views/Channel/ChannelMapping'), meta: { title: 'Room Mapping', menuId: 'channel' } },
      //     { path: 'roomMapping', name: 'channelRoomMapping', component: () => import(/* webpackChunkName: "ChannelRoomMapping" */ '../views/Channel/ChannelRoomMapping'), meta: { title: 'Room Mapping', menuId: 'channel' } },
      //     { path: 'ratesAvailability', name: 'channelRatesAndAvailability', component: () => import(/* webpackChunkName: "ChannelRatesAndAvailability" */ '../views/Channel/ChannelRatesAndAvailability'), meta: { title: 'Rates And Availability', menuId: 'channel', hideSideBar: true } }
      //   ]
      // },
      { path: '/bookings', name: 'bookings', component: () => import(/* webpackChunkName: "Bookings" */ '../views/Bookings/Bookings.vue'), meta: { title: 'Bookings', menuId: 'bookings', type: 'list' } },
      { path: '/bookings/archived', name: 'archivedBookings', component: () => import(/* webpackChunkName: "ArchivedBookings" */ '../views/Bookings/Bookings.vue'), meta: { title: 'Archived Bookings', menuId: 'bookings', type: 'archived' } },
      { path: '/bookings/arrivalDeparture', name: 'arrivalDeparture', component: () => import(/* webpackChunkName: "ArrivalDeparture" */ '../views/Bookings/ArrivalDeparture.vue'), meta: { title: 'Arrivals', menuId: 'bookings' } },
      { path: '/bookings/noRoomList', name: 'noRoomList', component: () => import(/* webpackChunkName: "NoRoomList" */ '../views/Bookings/NoRoomList.vue'), meta: { title: 'No Room List', menuId: 'bookings' } },
      { path: '/bookings/groupBookings', name: 'groupBookings', component: () => import(/* webpackChunkName: "GroupBooking" */ '../views/Bookings/GroupBooking.vue'), meta: { title: 'Group Booking', menuId: 'bookings' } },
      { path: '/bookings/detail/:id', name: 'bookingsDetail', component: () => import(/* webpackChunkName: "BookingDetail" */ '../views/Bookings/BookingDetail.vue'), meta: { title: 'Booking Detail', menuId: 'bookings' } },
      { path: '/customers', name: 'customers', component: () => import(/* webpackChunkName: "Customers" */ '../views/Customers/Customers.vue'), meta: { title: 'Customers' } },
      { path: '/customers/detail/:id', name: 'customerDetail', component: () => import(/* webpackChunkName: "CustomersDetail" */ '../views/Customers/CustomerDetail.vue'), meta: { title: 'Customer Detail' } },
      { path: '/houses', name: 'houses', component: () => import(/* webpackChunkName: "Houses" */ '../views/Houses/Houses.vue'), meta: { title: 'Houses' } },
      { path: '/houses/add', name: 'createHouse', component: () => import(/* webpackChunkName: "CreateNewHouse" */ '../views/Houses/CreateNewHouse.vue'), meta: { title: 'Create new House' } },
      { path: '/houses/onBoard/contact', name: 'houseOnBoardContact', component: () => import(/* webpackChunkName: "houseOnBoardContact" */ '../views/Houses/OnBoard/HouseOnBoardContact.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/onBoard/amenities', name: 'houseOnBoardAmenities', component: () => import(/* webpackChunkName: "houseOnBoardAmenities" */ '../views/Houses/OnBoard/HouseOnBoardAmenities.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/onBoard/info', name: 'houseOnBoardRules', component: () => import(/* webpackChunkName: "houseOnBoardRules" */ '../views/Houses/OnBoard/HouseOnBoardInfo.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/onBoard/images', name: 'houseOnBoardImages', component: () => import(/* webpackChunkName: "HouseOnBoardImages" */ '../views/Houses/OnBoard/HouseOnBoardImages.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/onBoard/parking', name: 'houseOnBoardParking', component: () => import(/* webpackChunkName: "houseOnBoardParking" */ '../views/Houses/OnBoard/HouseOnBoardParking.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/onBoard/wifi', name: 'houseOnBoardWifi', component: () => import(/* webpackChunkName: "houseOnBoardWifi" */ '../views/Houses/OnBoard/HouseOnBoardWifi.vue'), meta: { title: 'On Board House' } },
      { path: '/houses/detail', name: 'viewHouse', component: () => import(/* webpackChunkName: "HouseDetail" */ '../views/Houses/HouseView.vue'), meta: { title: 'House View' } },
      { path: '/rooms', name: 'rooms', component: () => import(/* webpackChunkName: "Rooms" */ '../views/Rooms/Rooms.vue'), meta: { title: 'Rooms', menuId: 'rooms' } },
      { path: '/rooms/categories', name: 'roomCategories', component: () => import(/* webpackChunkName: "RoomCategories" */ '../views/Rooms/RoomCategories.vue'), meta: { title: 'Room Categories', menuId: 'rooms' } },
      { path: '/rooms/categories/onBoard/pricing', name: 'roomCategoryOnBoardPricing', component: () => import(/* webpackChunkName: "roomCategoryOnBoardPricing" */ '../views/Rooms/Category/OnBoard/CategoryOnBoardPricing.vue'), meta: { title: 'On Board Category' } },
      { path: '/rooms/categories/onBoard/amenities', name: 'roomCategoryOnBoardAmenities', component: () => import(/* webpackChunkName: "roomCategoryOnBoardAmenities" */ '../views/Rooms/Category/OnBoard/CategoryOnBoardAmenities.vue'), meta: { title: 'On Board Category' } },
      { path: '/rooms/categories/onBoard/kidsPricing', name: 'roomCategoryOnBoardKidsPrice', component: () => import(/* webpackChunkName: "roomCategoryOnBoardKidsPrice" */ '../views/Rooms/Category/OnBoard/CategoryOnBoardKids.vue'), meta: { title: 'On Board Category' } },
      { path: '/rooms/ratePlans', name: 'ratePlans', component: () => import(/* webpackChunkName: "ratePlans" */ '../views/Rooms/RoomRatePlans.vue'), meta: { title: 'Rate Plans', menuId: 'rooms' } },
      { path: '/rooms/blockOuts', name: 'blockOutDays', component: () => import(/* webpackChunkName: "RoomBlockOutDays" */ '../views/Rooms/RoomBlockOutDays.vue'), meta: { title: 'Block Out Days', menuId: 'rooms', hideSideBar: true } },
      // { path: '/eLock', name: 'eLock', component: () => import(/* webpackChunkName: "ELock" */ '../views/ELock/ELock.vue'), meta: { title: 'E-Lock' } },
      { path: '/scsn', name: 'scsn', component: () => import(/* webpackChunkName: "Scsn" */ '../views/ELock/Scsn.vue'), meta: { title: 'SCSN' } },
      { path: '/eLock/commonDoors', name: 'commonDoors', component: () => import(/* webpackChunkName: "ELockCommonDoors" */ '../views/ELock/ELockCommonDoors.vue'), meta: { title: 'Common Doors' } },
      { path: '/eLock/hosGateways', name: 'hosGateways', component: () => import(/* webpackChunkName: "hosGateways" */ '../views/ELock/HosGateWays.vue'), meta: { title: 'Gateways' } },
      { path: '/eLock/detail/:lockId', name: 'eLockDetail', component: () => import(/* webpackChunkName: "ELockDetail" */ '../views/ELock/ELockDetail.vue'), meta: { title: 'E-Lock Detail' } },
      { path: '/eLock/:lockType', name: 'eLock', component: () => import(/* webpackChunkName: "ELock" */ '../views/ELock/ELock.vue'), meta: { title: 'E-Lock' } },
      { path: '/houseKeeping', name: 'houseKeeping', component: () => import(/* webpackChunkName: "HouseKeeping" */ '../views/HouseKeeping/HouseKeeping'), meta: { title: 'HouseKeeping', menuId: 'houseKeeping' } },
      { path: '/houseKeeping/jobs', name: 'HouseTechnicJobs', component: () => import(/* webpackChunkName: "HouseTechnicJobs" */ '../views/HouseKeeping/HouseTechnicJobs'), meta: { title: 'HouseTechnicJobs', menuId: 'houseKeeping' } },
      { path: '/houseKeeping/reports', name: 'HouseKeepingReports', component: () => import(/* webpackChunkName: "HouseTechnicJobs" */ '../views/HouseKeeping/HouseKeepingReports.vue'), meta: { title: 'HouseKeeping Reports', menuId: 'houseKeeping' } },
      { path: '/contents/mails', name: 'customMails', component: () => import(/* webpackChunkName: "Mails" */ '../views/Contents/Mails'), meta: { title: 'Mails', menuId: 'contents' } },
      { path: '/contents/wifi', name: 'wifiCodes', component: () => import(/* webpackChunkName: "WifiCodes" */ '../views/Contents/WifiCodes'), meta: { title: 'WifiCodes', menuId: 'contents' } },
      { path: '/articles', name: 'articles', component: () => import(/* webpackChunkName: "Articles" */ '../views/Articles.vue'), meta: { title: 'Articles', menuId: 'articles' } },
      { path: '/staffs', name: 'staffs', component: () => import(/* webpackChunkName: "Staffs" */ '../views/Staffs.vue'), meta: { title: 'Staffs' } },
      { path: '/settings', name: 'settings', component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue'), meta: { title: 'Settings' } },
      { path: '/cashBook', name: 'cashBook', component: () => import(/* webpackChunkName: "ReportCashBook" */ '../views/CashBook/CashBook.vue'), meta: { title: 'Cash Book', menuId: 'reports' } },
      { path: '/reports', name: 'reports', component: () => import(/* webpackChunkName: "ReportsHome" */ '../views/Reports/ReportsHome.vue'), meta: { title: 'Reports', menuId: 'reports' } },
      { path: '/reports/monthly', name: 'monthlyReport', component: () => import(/* webpackChunkName: "ReportMonthly" */ '../views/Reports/ReportMonthly.vue'), meta: { title: 'Monthly Report', menuId: 'reports' } },
      { path: '/reports/payments', name: 'paymentReport', component: () => import(/* webpackChunkName: "ReportsPayments" */ '../views/Reports/ReportsPayments.vue'), meta: { title: 'Payment Report', menuId: 'reports' } },
      { path: '/reports/arrivalsDeparture', name: 'paymentArrivals', component: () => import(/* webpackChunkName: "ReportArrivals" */ '../views/Reports/ReportArrivals.vue'), meta: { title: 'Arrival Report', menuId: 'reports' } },
      { path: '/reports/invoices', name: 'repportInoices', component: () => import(/* webpackChunkName: "ReportInvoices" */ '../views/Reports/ReportInvoices.vue'), meta: { title: 'Report | Invoices', menuId: 'reports' } },
      { path: '/reports/articles', name: 'articlesReport', component: () => import(/* webpackChunkName: "ReportArticles" */ '../views/Reports/ReportArticles.vue'), meta: { title: 'Article Report', menuId: 'reports' } },
      { path: '/reports/guestReporting', name: 'guestReporting', component: () => import(/* webpackChunkName: "ReportGuestReporting" */ '../views/Reports/ReportGuestReporting.vue'), meta: { title: 'Guest Reporting', menuId: 'reports' } },
      { path: '/reports/insurance', name: 'reportInsurance', component: () => import(/* webpackChunkName: "ReportInsurance" */ '../views/Reports/ReportInsurance.vue'), meta: { title: 'Insurance Report', menuId: 'reports' } },
      { path: '/reports/fiscalization', name: 'reportFiscalization', component: () => import(/* webpackChunkName: "ReportFiscalization" */ '../views/Reports/ReportFiscalization.vue'), meta: { title: 'Fiscalization Report', menuId: 'reports' } },
      { path: '/reports/commission', name: 'reportCommission', component: () => import(/* webpackChunkName: "ReportCommission" */ '../views/Reports/ReportCommission.vue'), meta: { title: 'Commission Report', menuId: 'reports' } },
      { path: '/reports/meal', name: 'meals', component: () => import(/* webpackChunkName: "meal" */ '../views/Reports/ReportMeals.vue'), meta: { title: 'Meals', menuId: 'reports' } },
      { path: '/profile', name: 'profile', component: () => import(/* webpackChunkName: "Profile" */ '../views/Profile/Profile.vue'), meta: { title: 'Profile' } },
      { path: '/kassa', name: 'kassa', component: () => import(/* webpackChunkName: "Kassa" */ '../views/Kassa/Kassa.vue'), meta: { title: 'Kassa' } },
      { path: '/restaurant', name: 'restaurant', component: () => import(/* webpackChunkName: "restaurant" */ '../views/Restaurant/RestaurantOrders.vue'), meta: { title: 'Restaurant', menuId: 'restaurant' } },
      { path: '/restaurant/categories', name: 'restaurantCategories', component: () => import(/* webpackChunkName: "restaurantCategories" */ '../views/Restaurant/RestaurantCategories.vue'), meta: { title: 'Restaurant Categories', menuId: 'restaurant' } },
      { path: '/restaurant/subCategories', name: 'restaurantSubCategories', component: () => import(/* webpackChunkName: "restaurantSubCategories" */ '../views/Restaurant/RestaurantSubCategories.vue'), meta: { title: 'Restaurant Sub Categories', menuId: 'restaurant' } },
      { path: '/restaurant/articles', name: 'restaurantArticles', component: () => import(/* webpackChunkName: "restaurantArticles" */ '../views/Restaurant/RestaurantArticles.vue'), meta: { title: 'Restaurant Articles', menuId: 'restaurant' } },
      { path: '/restaurant/recipes', name: 'restaurantRecipes', component: () => import(/* webpackChunkName: "restaurantRecipes" */ '../views/Restaurant/RestaurantRecipes.vue'), meta: { title: 'Restaurant Recipes', menuId: 'restaurant' } },
      { path: '/restaurant/tables', name: 'restaurantTables', component: () => import(/* webpackChunkName: "restaurantTables" */ '../views/Restaurant/RestaurantTables.vue'), meta: { title: 'Restaurant Tables', menuId: 'restaurant' } },
      { path: '/restaurant/kassa', name: 'restaurantKassa', component: () => import(/* webpackChunkName: "restaurantKassa" */ '../views/Restaurant/RestaurantKiosks.vue'), meta: { title: 'Restaurant Kiosks', menuId: 'restaurant' } },
      { path: '/restaurant/payments', name: 'restaurantPayments', component: () => import(/* webpackChunkName: "restaurantPayments" */ '../views/Restaurant/RestaurantPayments.vue'), meta: { title: 'Restaurant Payments', menuId: 'restaurant' } },
      { path: '/invoices', name: 'invoices', component: () => import(/* webpackChunkName: "Invoices" */ '../views/Invoices/Invoices.vue'), meta: { title: 'Invoices', menuId: 'invoices', type: 'invoice' } },
      { path: '/invoices/create', name: 'createInvoice', component: () => import(/* webpackChunkName: "createInvoice" */ '../views/Invoices/InvoiceCreate.vue'), meta: { title: 'New Invoice', menuId: 'invoices', hideSideBar: true } },
      { path: '/invoices/:type', name: 'Offers', component: () => import(/* webpackChunkName: "InvoiceOffers" */ '../views/Invoices/Invoices.vue'), meta: { title: 'Invoices', menuId: 'invoices', type: 'Invoices' } },
      // { path: '/invoices/archived', name: 'InvoiceArchived', component: () => import(/* webpackChunkName: "InvoiceArchived" */ '../views/Invoices/Invoices.vue'), meta: { title: 'Invoice Archived', menuId: 'invoices', type: 'archived' } },
      { path: '/invoices/detail/:invoiceId', name: 'InvoiceDetail', component: () => import(/* webpackChunkName: "InvoiceDetail" */ '../views/Invoices/InvoiceDetail.vue'), meta: { title: 'Detail', menuId: 'invoices' } },
      { path: '/invoices/edit/:invoiceId', name: 'InvoiceEdit', component: () => import(/* webpackChunkName: "InvoiceEdit" */ '../views/Invoices/InvoiceEdit.vue'), meta: { title: 'Edit', menuId: 'invoices', hideSideBar: true } },
      {
        path: '/mailBox', name: 'mailBox', component: () => import(/* webpackChunkName: "MailBoxHome" */ '../views/MailBox/Index.vue'), meta: { title: 'Mail Box', hideSideBar: true }
      },
      {
        path: '/mailBox/msAuth', name: 'msAuth', component: () => import(/* webpackChunkName: "MailBoxHome" */ '../views/MailBox/MailBoxMsAuth.vue'), meta: { title: 'Mail Box Authorization', hideSideBar: true }
      },
      { path: '/integrations', name: 'integrations', component: () => import(/*webpackChunkName: "Integrations"*/ '../views/Integrations/Integrations.vue'), meta: { title: 'Integrations' }},
      { path: '/integrations/superChat', name: 'Superchat', component: () => import(/*webpackChunkName: "IntegrationsSuperChat"*/ '../views/Integrations/messaging/SuperChat.vue'), meta: { title: 'Superchat' }},
      { path: '*', name: 'pageNotFound', component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound.vue'), meta: { title: 'Settings' } },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'singleMenuActive',
  linkExactActiveClass: 'exact-active',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
        behavior: 'smooth'
      }
    }
  }
})

const DEFAULT_TITLE = 'Hos-Booking'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    const isAuthRequire = to.meta.auth
    const routeName = to.name
    const authToken = localStorage.getItem('HosAdminAuthToken')
    const adminData = JSON.parse(localStorage.getItem('HosAdminData'))
    const isMainAdmin = (adminData === null || adminData.isMainAdmin === undefined) ? false : adminData.isMainAdmin
    if ((isAuthRequire === undefined || isAuthRequire) && to.name !== from.name) {
      if (authToken !== null) {
        const role = (store.state.user.role !== undefined) ? store.state.user.role : null
        if (role === '3' && routeName !== 'chamberMaid') {
          next({ path: '/login' })
        } else if (role === '4' && routeName !== 'houseTechnician') {
          next({ path: '/login' })
        } else if ((routeName === 'staffs' || routeName === 'settings') && isMainAdmin) {
          next()
        } else if ((routeName === 'staffs' || routeName === 'settings')) {
          next({ path: '/' })
        } else if (routeName === 'scsn' && !adminData.scsnAccess) {
          next({ path: '/' })
        } else if (routeName === 'eLock' && !adminData.eLockAccess) {
          next({ path: '/' })
        } else {
          next()
        }
      } else {
        next({ path: '/login' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
