const i18n = require('@/i18n');

const countryISOMapping = {
  AUT: { iso2: "AT", dialCode: '+43', name: 'Austria' },
  DEU: { iso2: "DE", dialCode: '+49', name: 'Germany' },
  ITA: { iso2: "IT", dialCode: '+39', name: 'Italy' },
  AFG: { iso2: "AF", dialCode: '+93', name: 'Afghanistan' },
  ALB: { iso2: "AL", dialCode: '+355', name: 'Albania' },
  DZA: { iso2: "DZ", dialCode: '+213', name: 'Algeria' },
  ASM: { iso2: "AS", dialCode: '+1-684', name: 'American Samoa' },
  AND: { iso2: "AD", dialCode: '+376', name: 'Andorra' },
  AGO: { iso2: "AO", dialCode: '+244', name: 'Angola' },
  AIA: { iso2: "AI", dialCode: '+1-264', name: 'Anguilla' },
  ATA: { iso2: "AQ", dialCode: '+672', name: 'Antarctica' },
  ATG: { iso2: "AG", dialCode: '+1-268', name: 'Antigua and Barbuda' },
  ARG: { iso2: "AR", dialCode: '+54', name: 'Argentina' },
  ARM: { iso2: "AM", dialCode: '+374', name: 'Armenia' },
  ABW: { iso2: "AW", dialCode: '+297', name: 'Aruba' },
  AUS: { iso2: "AU", dialCode: '+61', name: 'Australia' },
  AZE: { iso2: "AZ", dialCode: '+994', name: 'Azerbaijan' },
  BHS: { iso2: "BS", dialCode: '+1-242', name: 'Bahamas' },
  BHR: { iso2: "BH", dialCode: '+973', name: 'Bahrain' },
  BGD: { iso2: "BD", dialCode: '+880', name: 'Bangladesh' },
  BRB: { iso2: "BB", dialCode: '+1-246', name: 'Barbados' },
  BLR: { iso2: "BY", dialCode: '+375', name: 'Belarus' },
  BEL: { iso2: "BE", dialCode: '+32', name: 'Belgium' },
  BLZ: { iso2: "BZ", dialCode: '+501', name: 'Belize' },
  BEN: { iso2: "BJ", dialCode: '+229', name: 'Benin' },
  BMU: { iso2: "BM", dialCode: '+1-441', name: 'Bermuda' },
  BTN: { iso2: "BT", dialCode: '+975', name: 'Bhutan' },
  BOL: { iso2: "BO", dialCode: '+591', name: 'Bolivia' },
  BIH: { iso2: "BA", dialCode: '+387', name: 'Bosnia and Herzegovina' },
  BWA: { iso2: "BW", dialCode: '+267', name: 'Botswana' },
  BRA: { iso2: "BR", dialCode: '+55', name: 'Brazil' },
  IOT: { iso2: "IO", dialCode: '+246', name: 'British Indian Ocean Territory' },
  VGB: { iso2: "VG", dialCode: '+1-284', name: 'British Virgin Islands' },
  BRN: { iso2: "BN", dialCode: '+673', name: 'Brunei' },
  BGR: { iso2: "BG", dialCode: '+359', name: 'Bulgaria' },
  BFA: { iso2: "BF", dialCode: '+226', name: 'Burkina Faso' },
  BDI: { iso2: "BI", dialCode: '+257', name: 'Burundi' },
  KHM: { iso2: "KH", dialCode: '+855', name: 'Cambodia' },
  CMR: { iso2: "CM", dialCode: '+237', name: 'Cameroon' },
  CAN: { iso2: "CA", dialCode: '+1', name: 'Canada' },
  CPV: { iso2: "CV", dialCode: '+238', name: 'Cape Verde' },
  CYM: { iso2: "KY", dialCode: '+1-345', name: 'Cayman Islands' },
  CAF: { iso2: "CF", dialCode: '+236', name: 'Central African Republic' },
  TCD: { iso2: "TD", dialCode: '+235', name: 'Chad' },
  CHL: { iso2: "CL", dialCode: '+56', name: 'Chile' },
  CHN: { iso2: "CN", dialCode: '+86', name: 'China' },
  CXR: { iso2: "CX", dialCode: '+61', name: 'Christmas Island' },
  CCK: { iso2: "CC", dialCode: '+61', name: 'Cocos Islands' },
  COL: { iso2: "CO", dialCode: '+57', name: 'Colombia' },
  COM: { iso2: "KM", dialCode: '+269', name: 'Comoros' },
  COK: { iso2: "CK", dialCode: '+682', name: 'Cook Islands' },
  CRI: { iso2: "CR", dialCode: '+506', name: 'Costa Rica' },
  HRV: { iso2: "HR", dialCode: '+385', name: 'Croatia' },
  CUB: { iso2: "CU", dialCode: '+53', name: 'Cuba' },
  CUW: { iso2: "CW", dialCode: '+599', name: 'Curacao' },
  CYP: { iso2: "CY", dialCode: '+357', name: 'Cyprus' },
  CZE: { iso2: "CZ", dialCode: '+420', name: 'Czech Republic' },
  COD: { iso2: "CD", dialCode: '+243', name: 'Democratic Republic of the Congo' },
  DNK: { iso2: "DK", dialCode: '+45', name: 'Denmark' },
  DJI: { iso2: "DJ", dialCode: '+253', name: 'Djibouti' },
  DMA: { iso2: "DM", dialCode: '+1-767', name: 'Dominica' },
  DOM: { iso2: "DO", dialCode: '+1-809', name: 'Dominican Republic' },
  TLS: { iso2: "TL", dialCode: '+670', name: 'East Timor' },
  ECU: { iso2: "EC", dialCode: '+593', name: 'Ecuador' },
  EGY: { iso2: "EG", dialCode: '+20', name: 'Egypt' },
  SLV: { iso2: "SV", dialCode: '+503', name: 'El Salvador' },
  GNQ: { iso2: "GQ", dialCode: '+240', name: 'Equatorial Guinea' },
  ERI: { iso2: "ER", dialCode: '+291', name: 'Eritrea' },
  EST: { iso2: "EE", dialCode: '+372', name: 'Estonia' },
  ETH: { iso2: "ET", dialCode: '+251', name: 'Ethiopia' },
  FLK: { iso2: "FK", dialCode: '+500', name: 'Falkland Islands' },
  FRO: { iso2: "FO", dialCode: '+298', name: 'Faroe Islands' },
  FJI: { iso2: "FJ", dialCode: '+679', name: 'Fiji' },
  FIN: { iso2: "FI", dialCode: '+358', name: 'Finland' },
  FRA: { iso2: "FR", dialCode: '+33', name: 'France' },
  PYF: { iso2: "PF", dialCode: '+689', name: 'French Polynesia' },
  GAB: { iso2: "GA", dialCode: '+241', name: 'Gabon' },
  GMB: { iso2: "GM", dialCode: '+220', name: 'Gambia' },
  GEO: { iso2: "GE", dialCode: '+995', name: 'Georgia' },
  GHA: { iso2: "GH", dialCode: '+233', name: 'Ghana' },
  GIB: { iso2: "GI", dialCode: '+350', name: 'Gibraltar' },
  GRC: { iso2: "GR", dialCode: '+30', name: 'Greece' },
  GRL: { iso2: "GL", dialCode: '+299', name: 'Greenland' },
  GRD: { iso2: "GD", dialCode: '+1-473', name: 'Grenada' },
  GUM: { iso2: "GU", dialCode: '+1-671', name: 'Guam' },
  GTM: { iso2: "GT", dialCode: '+502', name: 'Guatemala' },
  GGY: { iso2: "GG", dialCode: '+44-1481', name: 'Guernsey' },
  GIN: { iso2: "GN", dialCode: '+224', name: 'Guinea' },
  GNB: { iso2: "GW", dialCode: '+245', name: 'Guinea-Bissau' },
  GUY: { iso2: "GY", dialCode: '+592', name: 'Guyana' },
  HTI: { iso2: "HT", dialCode: '+509', name: 'Haiti' },
  HND: { iso2: "HN", dialCode: '+504', name: 'Honduras' },
  HKG: { iso2: "HK", dialCode: '+852', name: 'Hong Kong' },
  HUN: { iso2: "HU", dialCode: '+36', name: 'Hungary' },
  ISL: { iso2: "IS", dialCode: '+354', name: 'Iceland' },
  IND: { iso2: "IN", dialCode: '+91', name: 'India' },
  IDN: { iso2: "ID", dialCode: '+62', name: 'Indonesia' },
  IRN: { iso2: "IR", dialCode: '+98', name: 'Iran' },
  IRQ: { iso2: "IQ", dialCode: '+964', name: 'Iraq' },
  IRL: { iso2: "IE", dialCode: '+353', name: 'Ireland' },
  IMN: { iso2: "IM", dialCode: '+44-1624', name: 'Isle of Man' },
  ISR: { iso2: "IL", dialCode: '+972', name: 'Israel' },
  CIV: { iso2: "CI", dialCode: '+225', name: 'Ivory Coast' },
  JAM: { iso2: "JM", dialCode: '+1-876', name: 'Jamaica' },
  JPN: { iso2: "JP", dialCode: '+81', name: 'Japan' },
  JEY: { iso2: "JE", dialCode: '+44-1534', name: 'Jersey' },
  JOR: { iso2: "JO", dialCode: '+962', name: 'Jordan' },
  KAZ: { iso2: "KZ", dialCode: '+7', name: 'Kazakhstan' },
  KEN: { iso2: "KE", dialCode: '+254', name: 'Kenya' },
  KIR: { iso2: "KI", dialCode: '+686', name: 'Kiribati' },
  XKX: { iso2: "XK", dialCode: '+383', name: 'Kosovo' },
  KWT: { iso2: "KW", dialCode: '+965', name: 'Kuwait' },
  KGZ: { iso2: "KG", dialCode: '+996', name: 'Kyrgyzstan' },
  LAO: { iso2: "LA", dialCode: '+856', name: 'Laos' },
  LVA: { iso2: "LV", dialCode: '+371', name: 'Latvia' },
  LBN: { iso2: "LB", dialCode: '+961', name: 'Lebanon' },
  LSO: { iso2: "LS", dialCode: '+266', name: 'Lesotho' },
  LBR: { iso2: "LR", dialCode: '+231', name: 'Liberia' },
  LBY: { iso2: "LY", dialCode: '+218', name: 'Libya' },
  LIE: { iso2: "LI", dialCode: '+423', name: 'Liechtenstein' },
  LTU: { iso2: "LT", dialCode: '+370', name: 'Lithuania' },
  LUX: { iso2: "LU", dialCode: '+352', name: 'Luxembourg' },
  MAC: { iso2: "MO", dialCode: '+853', name: 'Macau' },
  MKD: { iso2: "MK", dialCode: '+389', name: 'Macedonia' },
  MDG: { iso2: "MG", dialCode: '+261', name: 'Madagascar' },
  MWI: { iso2: "MW", dialCode: '+265', name: 'Malawi' },
  MYS: { iso2: "MY", dialCode: '+60', name: 'Malaysia' },
  MDV: { iso2: "MV", dialCode: '+960', name: 'Maldives' },
  MLI: { iso2: "ML", dialCode: '+223', name: 'Mali' },
  MLT: { iso2: "MT", dialCode: '+356', name: 'Malta' },
  MHL: { iso2: "MH", dialCode: '+692', name: 'Marshall Islands' },
  MRT: { iso2: "MR", dialCode: '+222', name: 'Mauritania' },
  MUS: { iso2: "MU", dialCode: '+230', name: 'Mauritius' },
  MYT: { iso2: "YT", dialCode: '+262', name: 'Mayotte' },
  MEX: { iso2: "MX", dialCode: '+52', name: 'Mexico' },
  FSM: { iso2: "FM", dialCode: '+691', name: 'Micronesia' },
  MDA: { iso2: "MD", dialCode: '+373', name: 'Moldova' },
  MCO: { iso2: "MC", dialCode: '+377', name: 'Monaco' },
  MNG: { iso2: "MN", dialCode: '+976', name: 'Mongolia' },
  MNE: { iso2: "ME", dialCode: '+382', name: 'Montenegro' },
  MSR: { iso2: "MS", dialCode: '+1-664', name: 'Montserrat' },
  MAR: { iso2: "MA", dialCode: '+212', name: 'Morocco' },
  MOZ: { iso2: "MZ", dialCode: '+258', name: 'Mozambique' },
  MMR: { iso2: "MM", dialCode: '+95', name: 'Myanmar' },
  NAM: { iso2: "NA", dialCode: '+264', name: 'Namibia' },
  NRU: { iso2: "NR", dialCode: '+674', name: 'Nauru' },
  NPL: { iso2: "NP", dialCode: '+977', name: 'Nepal' },
  NLD: { iso2: "NL", dialCode: '+31', name: 'Netherlands' },
  ANT: { iso2: "AN", dialCode: '+599', name: 'Netherlands Antilles' },
  NCL: { iso2: "NC", dialCode: '+687', name: 'New Caledonia' },
  NZL: { iso2: "NZ", dialCode: '+64', name: 'New Zealand' },
  NIC: { iso2: "NI", dialCode: '+505', name: 'Nicaragua' },
  NER: { iso2: "NE", dialCode: '+227', name: 'Niger' },
  NGA: { iso2: "NG", dialCode: '+234', name: 'Nigeria' },
  NIU: { iso2: "NU", dialCode: '+683', name: 'Niue' },
  PRK: { iso2: "KP", dialCode: '+850', name: 'North Korea' },
  MNP: { iso2: "MP", dialCode: '+1-670', name: 'Northern Mariana Islands' },
  NOR: { iso2: "NO", dialCode: '+47', name: 'Norway' },
  OMN: { iso2: "OM", dialCode: '+968', name: 'Oman' },
  PAK: { iso2: "PK", dialCode: '+92', name: 'Pakistan' },
  PLW: { iso2: "PW", dialCode: '+680', name: 'Palau' },
  PSE: { iso2: "PS", dialCode: '+970', name: 'Palestine' },
  PAN: { iso2: "PA", dialCode: '+507', name: 'Panama' },
  PNG: { iso2: "PG", dialCode: '+675', name: 'Papua New Guinea' },
  PRY: { iso2: "PY", dialCode: '+595', name: 'Paraguay' },
  PER: { iso2: "PE", dialCode: '+51', name: 'Peru' },
  PHL: { iso2: "PH", dialCode: '+63', name: 'Philippines' },
  PCN: { iso2: "PN", dialCode: '+64', name: 'Pitcairn' },
  POL: { iso2: "PL", dialCode: '+48', name: 'Poland' },
  PRT: { iso2: "PT", dialCode: '+351', name: 'Portugal' },
  PRI: { iso2: "PR", dialCode: '+1-787', name: 'Puerto Rico' },
  QAT: { iso2: "QA", dialCode: '+974', name: 'Qatar' },
  COG: { iso2: "CG", dialCode: '+242', name: 'Republic of the Congo' },
  REU: { iso2: "RE", dialCode: '+262', name: 'Reunion' },
  ROU: { iso2: "RO", dialCode: '+40', name: 'Romania' },
  RUS: { iso2: "RU", dialCode: '+7', name: 'Russia' },
  RWA: { iso2: "RW", dialCode: '+250', name: 'Rwanda' },
  BLM: { iso2: "BL", dialCode: '+590', name: 'Saint Barthelemy' },
  SHN: { iso2: "SH", dialCode: '+290', name: 'Saint Helena' },
  KNA: { iso2: "KN", dialCode: '+1-869', name: 'Saint Kitts and Nevis' },
  LCA: { iso2: "LC", dialCode: '+1-758', name: 'Saint Lucia' },
  MAF: { iso2: "MF", dialCode: '+590', name: 'Saint Martin' },
  SPM: { iso2: "PM", dialCode: '+508', name: 'Saint Pierre and Miquelon' },
  VCT: { iso2: "VC", dialCode: '+1-784', name: 'Saint Vincent and the Grenadines' },
  WSM: { iso2: "WS", dialCode: '+685', name: 'Samoa' },
  SMR: { iso2: "SM", dialCode: '+378', name: 'San Marino' },
  STP: { iso2: "ST", dialCode: '+239', name: 'Sao Tome and Principe' },
  SAU: { iso2: "SA", dialCode: '+966', name: 'Saudi Arabia' },
  SEN: { iso2: "SN", dialCode: '+221', name: 'Senegal' },
  SRB: { iso2: "RS", dialCode: '+381', name: 'Serbia' },
  SYC: { iso2: "SC", dialCode: '+248', name: 'Seychelles' },
  SLE: { iso2: "SL", dialCode: '+232', name: 'Sierra Leone' },
  SGP: { iso2: "SG", dialCode: '+65', name: 'Singapore' },
  SXM: { iso2: "SX", dialCode: '+1-721', name: 'Sint Maarten' },
  SVK: { iso2: "SK", dialCode: '+421', name: 'Slovakia' },
  SVN: { iso2: "SI", dialCode: '+386', name: 'Slovenia' },
  SLB: { iso2: "SB", dialCode: '+677', name: 'Solomon Islands' },
  SOM: { iso2: "SO", dialCode: '+252', name: 'Somalia' },
  ZAF: { iso2: "ZA", dialCode: '+27', name: 'South Africa' },
  KOR: { iso2: "KR", dialCode: '+82', name: 'South Korea' },
  SSD: { iso2: "SS", dialCode: '+211', name: 'South Sudan' },
  ESP: { iso2: "ES", dialCode: '+34', name: 'Spain' },
  LKA: { iso2: "LK", dialCode: '+94', name: 'Sri Lanka' },
  SDN: { iso2: "SD", dialCode: '+249', name: 'Sudan' },
  SUR: { iso2: "SR", dialCode: '+597', name: 'Suriname' },
  SJM: { iso2: "SJ", dialCode: '+47', name: 'Svalbard and Jan Mayen' },
  SWZ: { iso2: "SZ", dialCode: '+268', name: 'Swaziland' },
  SWE: { iso2: "SE", dialCode: '+46', name: 'Sweden' },
  CHE: { iso2: "CH", dialCode: '+41', name: 'Switzerland' },
  SYR: { iso2: "SY", dialCode: '+963', name: 'Syria' },
  TWN: { iso2: "TW", dialCode: '+886', name: 'Taiwan' },
  TJK: { iso2: "TJ", dialCode: '+992', name: 'Tajikistan' },
  TZA: { iso2: "TZ", dialCode: '+255', name: 'Tanzania' },
  THA: { iso2: "TH", dialCode: '+66', name: 'Thailand' },
  TGO: { iso2: "TG", dialCode: '+228', name: 'Togo' },
  TKL: { iso2: "TK", dialCode: '+690', name: 'Tokelau' },
  TON: { iso2: "TO", dialCode: '+676', name: 'Tonga' },
  TTO: { iso2: "TT", dialCode: '+1-868', name: 'Trinidad and Tobago' },
  TUN: { iso2: "TN", dialCode: '+216', name: 'Tunisia' },
  TUR: { iso2: "TR", dialCode: '+90', name: 'Turkey' },
  TKM: { iso2: "TM", dialCode: '+993', name: 'Turkmenistan' },
  TCA: { iso2: "TC", dialCode: '+1-649', name: 'Turks and Caicos Islands' },
  TUV: { iso2: "TV", dialCode: '+688', name: 'Tuvalu' },
  VIR: { iso2: "VI", dialCode: '+1-340', name: 'U.S. Virgin Islands' },
  UGA: { iso2: "UG", dialCode: '+256', name: 'Uganda' },
  UKR: { iso2: "UA", dialCode: '+380', name: 'Ukraine' },
  ARE: { iso2: "AE", dialCode: '+971', name: 'United Arab Emirates' },
  GBR: { iso2: "GB", dialCode: '+44', name: 'United Kingdom' },
  USA: { iso2: "US", dialCode: '+1', name: 'United States' },
  URY: { iso2: "UY", dialCode: '+598', name: 'Uruguay' },
  UZB: { iso2: "UZ", dialCode: '+998', name: 'Uzbekistan' },
  VUT: { iso2: "VU", dialCode: '+678', name: 'Vanuatu' },
  VAT: { iso2: "VA", dialCode: '+379', name: 'Vatican' },
  VEN: { iso2: "VE", dialCode: '+58', name: 'Venezuela' },
  VNM: { iso2: "VN", dialCode: '+84', name: 'Vietnam' },
  WLF: { iso2: "WF", dialCode: '+681', name: 'Wallis and Futuna' },
  ESH: { iso2: "EH", dialCode: '+212', name: 'Western Sahara' },
  YEM: { iso2: "YE", dialCode: '+967', name: 'Yemen' },
  ZMB: { iso2: "ZM", dialCode: '+260', name: 'Zambia' },
  ZWE: { iso2: "ZW", dialCode: '+263', name: 'Zimbabwe' },
}
module.exports = function getCountryISO2(countryCode) {
  return (countryISOMapping[countryCode]) ? countryISOMapping[countryCode].iso2 : ""
}

module.exports = function getCountriesDialCodes() {
  const output = [{
    value: '',
    text: 'Dial Code'
  }]
  Object.keys(countryISOMapping).forEach(key => {
    output.push({
      text: `${countryISOMapping[key].name} (${countryISOMapping[key].dialCode})`,
      value: countryISOMapping[key].dialCode
    })
  })
  return output
}
