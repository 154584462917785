<template>
  <div class="appWrapper min-vh-100">
    <div class="bodyLoader appBg" v-if="loading">
      <div class="col-12 d-flex h-100">
        <div class="w-100 justify-content-center align-self-center">
          <img src="./assets/images/hosLogo.png" height="80px" />
          <div class="w-100 py-3"></div>
          <b-spinner variant="success"></b-spinner>
          &nbsp;&nbsp;
          <b-spinner variant="danger"></b-spinner>&nbsp;&nbsp;
          <b-spinner variant="info"></b-spinner>&nbsp;&nbsp;
          <b-spinner variant="warning"></b-spinner>
        </div>
      </div>
    </div>
    <router-view v-else/>
    <vue-progress-bar></vue-progress-bar>
    <b-modal v-model="showModal"
             size="sm"
             body-class="p-0"
             centered no-fade
             hide-footer
             hide-header-close
             hide-header
             no-close-on-backdrop
             no-close-on-esc>
      <div class="w-100 text-center text-danger px-2 py-5">
        <div class="w-100 py-2 mb-2">
          <b-icon-alarm animation="throb" variant="danger" font-scale="6" />
        </div>
        <div class="w-100">
          <b>{{$t('sessionExpired')}}</b>
        </div>
        <div class="w-100 py-2">
          <b v-html="$t('youWillBeLoggedOutIn', [second])"></b>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style lang="scss">
.bodyLoader {
  text-align: center;
  position: fixed;
  height: 100%; width: 100%;
  left: 0; top: 0;
}
</style>
<script>
require('./assets/js/summernote/summernote')
export default {
  name: 'App',
  mounted () {
    this.checkDomain()
  },
  watch: {
    isIdle: function (newVal, oldval) {
      if (newVal && !oldval && !this.isInLogin) {
        const timerId = setInterval(() => {
          this.time -= 1000
          if (!this.isIdle) {
            clearInterval(timerId)
            this.time = 10000
          } else if (this.time < 1) {
            clearInterval(timerId)
            if (this.$route.path !== '/login') {
              this.$helper.setLogOut()
              this.$router.push({
                name: 'login'
              })
            }
          }
        }, 1000)
      }
    },
    '$i18n.locale': function (newLocale) {
      this.setCurrency()
    }
  },
  computed: {
    showModal () {
      return (this.isIdle && !this.isInLogin)
    },
    isIdle () {
      return this.$store.state.idleVue.isIdle
    },
    isInLogin () {
      return (this.$route.path === '/login')
    },
    second () {
      return this.time / 1000
    },
    currentPath () {
      return this.$route.path
    },
    currentPathName () {
      return this.$route.name
    }
  },
  created () {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', this.$i18n.locale)
    this.$router.beforeEach((to, from, next) => {
      this.time = 10000
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      if (from.name === 'login') {
        this.loadAllHouses(next)
      } else if (from.name === 'houses' || from.name === 'createHouse' || from.name === 'viewHouse') {
        this.loadAllHouses(next)
      } else {
        next()
      }
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
      if (from.name === 'login') {
        location.reload()
      }
    })
  },
  methods: {
    setCurrency () {
      const currency = this.$store.state.settings.currency // response.data.settings.currency
      const availableLocales = this.$i18n.availableLocales
      const currencyMap = {}
      availableLocales.forEach((index) => {
        currencyMap[index] = currency
      })
      const newCurrency = currencyMap[this.$i18n.locale] || 'EUR';
      this.$i18n.setNumberFormat(this.$i18n.locale, {
        currency: {
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          currency: newCurrency,
        },
        currencyNoCents: {
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          currency: newCurrency,
          minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
          maximumFractionDigits: 0
        },
        numberNoFraction: {
          style: 'decimal',
          currency: newCurrency,
          minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
          maximumFractionDigits: 0
        },
        numberNoFormat: {
          style: 'decimal',
          useGrouping: false,
          currency: newCurrency,
        }
      });
    },
    checkDomain () {
      this.loading = true
      this.$http.post('checkDomain', {
        from: 'backOffice'
      }).then((response) => {
          if (response.data.error === undefined || response.data.error) {
            this.redirectToPageNotFound()
          } else {
            const currentPath = this.$route.path
            this.$store.commit('setSettings', response.data)
            this.setCurrency()
            if (currentPath === '/error') {
              this.$helper.setLogOut()
              this.$router.push({
                name: 'login'
              })
              this.loading = false
            } else if (currentPath !== '/login') {
              if (currentPath === '/auth/admin') {
                this.validateAdminToken()
              } else {
                if (this.$helper.isLoggedIn()) {
                  this.validateToken()
                } else {
                  this.$helper.setLogOut()
                  const fullPath = this.$route.fullPath
                  if (fullPath !== '/' && fullPath !== '') {
                    this.$router.push({
                      name: 'login',
                      query: {
                        redirectTo: fullPath
                      }
                    })
                  } else {
                    this.$router.push({
                      name: 'login'
                    })
                  }
                  setTimeout(() => {
                    this.loading = false
                  }, 500)
                }
              }
            } else {
              this.loading = false
            }
          }
        }).catch(() => {
          this.redirectToPageNotFound()
        })
    },
    validateAdminToken () {
      const redirectId = this.$route.query?.redirectId ?? ''
      this.$http.post('auth/setAdminAuth', {
        id: this.$route.query?.session_id || ''
      }).then(response => {
        if (response.data.error === undefined || response.data.error) {
          this.$router.push('/login')
          this.loading = false
        } else {
          this.$helper.setLogin(response.data)
          this.loadAllHouses()
          this.$store.commit('setLoggedUser', response.data.user)
          if (redirectId === '' || redirectId === undefined) {
              this.$router.push('/')
          } else {
             this.$router.push({ name: 'bookingsDetail', params: { id: redirectId } })
          }
          this.loading = false
        }
      }).catch((error) => {
        this.$router.push('/login')
        this.loading = false
      })
    },
    validateToken () {
      this.$http.post('login/validateToken').then(response => {
        if (response.data.error === undefined || response.data.error) {
          this.$router.push('/login')
          this.loading = false
        } else {
          this.$helper.setLogin(response.data)
          this.$store.commit('setUpdateInfos', response.data)
          this.loadAllHouses()
          this.$store.commit('setLoggedUser', response.data.user)
          if (response.data.setUpFinished) {
            const role = parseInt(response.data.user.role)
            if (role === 3 && this.currentPath !== '/chamberMaid') {
              this.$router.push('/chamberMaid')
            } else if (role === 4 && this.currentPath !== '/houseTechnician') {
              this.$router.push('/houseTechnician')
            } else if (['onBoard','onBoardReservation','onBoardLogo','onBoardMailServer','onBoardReview'].includes(this.currentPathName)) {
              this.$router.push('/')
            }
          } else {
            if (this.currentPath !== '/onBoard') {
              this.$router.push({ name: 'onBoard' })
            }
          }
        }
      }).catch(() => {
        this.$router.push('/login')
        this.loading = false
      })
    },
    redirectToPageNotFound () {
      const currentPath = this.$route.path
      if (currentPath !== '/error') {
        this.$router.push('/error')
      }
      this.loading = false
    },
    async loadAllHouses (next = undefined) {
      const currentPath = this.$route.path
      if (currentPath !== '/error') {
        this.$store.commit('setHouseLoading')
        this.$http.get('admin/house/simpleList').then(response => {
          if (response.data.error === undefined) {
            this.$store.commit('setHouseError', {
              error: this.$t('errors.loadHouses')
            })
          } else if (response.data.error === false) {
            this.$store.commit('setHouseList', {
              list: response.data.list
            })
          } else if (response.data.error) {
            this.$store.commit('setHouseError', {
              error: response.data.message
            })
          }
        }).catch(error => {
          this.$store.commit('setHouseError', {
            error: error.toString()
          })
        }).finally(() => {
          this.loading = false
          if (next !== undefined) {
            next()
          }
        })
      } else {
        this.loading = false
        if (next !== undefined) {
          next()
        }
      }
    }
  },
  data () {
    return {
      loggedIn: false,
      loading: true,
      time: 10000,
      houses: {
        state: '',
        message: '',
        list: []
      },
      notifications: {
        title: '',
        body: ''
      }
    }
  }
}
</script>
